/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@include mat.core();

$md-primary: (
  50: #eaf3fd,
  100: #cbe2fa,
  200: #a8cff7,
  300: #85bcf4,
  400: #6aadf1,
  500: #153448,
  600: #153448,
  700: #153448,
  800: #153448,
  900: #153448,
  A100: #ffffff,
  A200: #edf4ff,
  A400: #bad4ff,
  A700: #a1c4ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-alternative: (
  50: #f3fcfb,
  100: #e1f8f5,
  200: #cdf3ef,
  300: #b9eee9,
  400: #aaebe4,
  500: #9be7df,
  600: #93e4db,
  700: #89e0d7,
  800: #7fddd2,
  900: #6dd7ca,
  A100: #ffffff,
  A200: #ffffff,
  A400: #e8fffc,
  A700: #cffff9,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-accent: (
  50: #f4f4f4,
  100: #f4f4f4,
  200: #f4f4f4,
  300: #f4f4f4,
  400: #f4f4f4,
  500: #f4f4f4,
  600: #f4f4f4,
  700: #f4f4f4,
  800: #f4f4f4,
  900: #f4f4f4,
  A100: #ffffff,
  A200: #f4f4f4,
  A400: #f4f4f4,
  A700: #f4f4f4,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-error: (
  50: #f9e0e0,
  100: #f0b3b3,
  200: #e68080,
  300: #db4d4d,
  400: #d42626,
  500: #cc0000,
  600: #c70000,
  700: #c00000,
  800: #b90000,
  900: #ad0000,
  A100: #ffd7d7,
  A200: #ffa4a4,
  A400: #ff7171,
  A700: #ff5858,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$dcps-primary: mat.define-palette($md-primary, 500);
$dcps-accent: mat.define-palette($md-accent, A200, A100, A400);
// The "warn" palette is optional and defaults to red if not specified.
$dcps-warn: mat.define-palette($md-error);

$dcps-theme: mat.define-light-theme(
  (
    color: (
      primary: $dcps-primary,
      accent: $dcps-accent,
      warn: $dcps-warn,
    ),
  )
);
@include mat.all-component-themes($dcps-theme);

:root {
  --primary-color: #153448;
  --primary-contrast: #ffffff;
  --accent-color: #f4f4f4;
  --accent-contrast: #ffffff;
}

html,
body {
  height: 100%;
  font-family: "Inter";
}

/* styles.scss */
@font-face {
  font-family: "dcps-inter-regular";
  src: url("./assets/fonts/Inter/static/Inter_18pt-Regular.ttf");
}

@font-face {
  font-family: "dcps-inter_semi_bold";
  src: url("./assets/fonts/Inter/static/Inter_18pt-SemiBold.ttf");
}

@font-face {
  font-family: "dcps-inter_bold";
  src: url("./assets/fonts/Inter/static/Inter_24pt-Bold.ttf");
}

@font-face {
  font-family: "dcps-inter_italic";
  src: url("./assets/fonts/Inter/static/Inter_18pt-Italic.ttf");
}

:has(.horizontal-scroll)::-webkit-scrollbar {
  display: none !important;
}

::-webkit-scrollbar {
  width: 0px;
  background-color: #f4f7f5;
}

::-webkit-scrollbar-thumb {
  background-color: #222823;
}

.special-date {
  background-color: #fb8b18 !important;
  color: #ffffff !important;
  border-radius: 50%;
  font-weight: bold;
}

.mat-mdc-form-field {
  width: 100% !important;
  height: auto !important;
  // margin: 10px 0px 30px 0px !important;
  font-size: 14px !important;
  font-family: "dcps-inter-regular" !important;
}

.mat-mdc-form-field .mdc-notched-outline {
  border-color: #d7d7d7 !important;
  box-shadow: 1px rgba(0, 0, 0, 0.1); /* Adjust values as needed */
}

.mat-mdc-form-field .mdc-notched-outline__leading,
.mat-mdc-form-field .mdc-notched-outline__trailing {
  border-color: #d7d7d7 !important;
}

.mat-mdc-form-field .mdc-notched-outline__notch {
  border-color: #d7d7d7 !important;
}

/* Focused state */
.mat-mdc-form-field.mat-mdc-form-field--focused .mdc-notched-outline {
  border-color: #d7d7d7 !important;
  box-shadow: 1px rgba(0, 0, 0, 0.1); /* Same shadow for focused state */
}

/* Hover state */
.mat-mdc-form-field:hover .mdc-notched-outline {
  border-color: #d7d7d7 !important;
  box-shadow: 1px rgba(0, 0, 0, 0.1); /* Same shadow for hover state */
}

.form-label {
  font-family: "dcps-inter-regular" !important;
  color: #2b2b2b !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.custom-dialog-top-sheet .mat-mdc-dialog-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  height: 100vh;
  top: 0;
  bottom: auto;
  margin: 0;
  animation: slide-from-top 0.3s ease-out; /* Custom animation */
}

.my-super-class {
  width: 5vw !important; /* Set the desired width */
  height: 12vh !important; /* Set the desired height */
  text-align: center; /* Center the text inside the box */
  border: 1px solid #ccc; /* Optional: Customize border */
  font-size: 16px; /* Optional: Adjust font size */
  margin-top: 8vh;
}

/* Slide from top animation */
@keyframes slide-from-top {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.mat-mdc-snack-bar-container.custom-notification-snackbar
  .mdc-snackbar__surface {
  background: var(--primary-color) !important;
  border-radius: 8px;
  width: 30vw;
  height: 18vh;
  cursor: pointer;
}

.mat-mdc-snack-bar-container.custom-snackbar.success .mdc-snackbar__surface {
  background: linear-gradient(to bottom, #d3ffec, #f2fcfb);
  border: 2px solid white;
  border-radius: 5px;
}

.mat-mdc-snack-bar-container.custom-snackbar.error .mdc-snackbar__surface {
  background: linear-gradient(to bottom, #ffeeee, #fcf9fb);
  border: 2px solid white;
  border-radius: 5px;
}

.mat-mdc-snack-bar-container.custom-snackbar.info .mdc-snackbar__surface {
  background: linear-gradient(to bottom, #e0f0ff, #f2f8ff);
  border: 2px solid white;
  border-radius: 10px;
}

.mat-mdc-snack-bar-container.custom-snackbar.warning .mdc-snackbar__surface {
  background: linear-gradient(to bottom, #fff5ed, #fdfaf9);
  border: 2px solid white;
  border-radius: 5px;
}
